import { React, useState, useEffect } from 'react';
import axios from 'axios';
import './Footer.css'
import Newsfoot from './Newsfoot';
import Pressfoot from './Pressfoot';
import logo from '../../images/logo.svg'

const Footer = () => {
    const [items, SetItems] = useState([])
    const [press, SetPress] = useState([])
    let site = sessionStorage.getItem("site")
    let port = sessionStorage.getItem("portalid")



    useEffect(() => {
        // axios.post('/news/items/footer', { "id": port })
        //     .then(function (response) {
        //         SetItems(response.data)
        //     })
    }, []);

    useEffect(() => {
        // axios.post('/pressnews/items/footer', { "id": port })
        //     .then(function (response) {
        //         SetPress(response.data)
        //     })
    }, []);



    return (
        <>
            <footer className={ site === "Myblock" ? "footer" : "footer-techie"}>
                <div className='container' >
                    <div >
                        {site === "Myblock" ? (
                            <div className='box-myblocks'>
                                <p className="justify-text">Myblocks is building Intelligent data systems <br/>  for betterment of neighborhoods.</p>
                                {/* <img src='../images/tech-logo-footer.png' alt='' /> */}

                            </div>) :

                            (
                                <div className='logo-techie'>
                                    <img src={logo} alt='' />

                                </div>)


                        }

                    </div>

                    <div className='box-myblocks'>

                        {/* <h3>NEWS</h3>
                        {items.map((dot) => {
                            return <Newsfoot key={dot.id} item={dot} />
                        })} */}

                        {site === "Myblock" ? (
                            <div className='box-myblocks logo'>

                            </div>) :

                            (
                                <div >
                                    <p className="tagline">Techieindex is a startup news portal that provides the latest updates from the world of entrepreneurship and helps users connect with the broader business community.</p>

                                </div>)
                        }
                    </div>

                    <div >

                        {site === "Myblock" ? (
                            <div className='box-myblocks logo'>


                                <i className='fa fa-envelope'></i>
                                <span> info@myblocks.in</span> <br />
                                <i className=' fa fa-headphones'></i>
                                <span> +91-04842955379</span>
                            </div>) :

                            (
                                <div className="tagline">
                                    <span> amelia@techie-index.com</span> <br />
                                    <i className=' fa fa-headphones'></i>
                                    <span>+1-847-261-4361</span>
                                </div>)


                        }


                        {/* <h3>CATEGORIES</h3>
                        <ul><li><span>News</span><label>(5)</label></li></ul>
                        <ul><li><span>Spotlight</span><label>(6)</label></li></ul>
                        <ul><li><span>Press Release</span><label>(7)</label></li></ul>
                        <ul><li><span>Vendors</span><label>(8)</label></li></ul> */}
                    </div>
                </div>

            </footer>
            <center className={site === "Myblock" ? "legal" : "legal-techie"}>
                <p> © 2024 all rights reserved</p>
            </center>

        </>
    )
}

export default Footer
