const Contact = () => {
    return (
        <div>
            <table>
                <tr>
                    <td>
                        <p >Please
                            contact us if you have questions, if you would
                            like to request additional information, or if you
                            would like to offer your feedback. If you do not
                            find what you are looking for, please send an
                            email to the below address</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p >&nbsp;</p>
                        <p>Corporate
                            Headquarters</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p><b>Techieindex,
                            Inc.&nbsp;</b><br />
                            4225 129th PL,Se No-4<br />
                            Bellevue,WA - 98006,USA<br />
                            Voice : +1 (847)-261-4361<br />
                            E-mail: <a href="mailto:amelia@techie-index.com">amelia@techie-index.com</a></p>
                    </td>
                </tr>
                <tr>
                    <td height="21">
                    </td>
                </tr>
                <tr>
                    <td>
                        <p><b>For
                            queries on advertising and sales</b><br />
                                E-Mail: <a href="mailto:amelia@techie-index.com">amelia@techie-index.com</a><br />
                                Voice : USA +1 (847)-261-4361</p>
                    </td>
                </tr>
                <tr>
                    <td height="21">
                    </td>
                </tr>
                <tr>
                    <td>
                        <p ><b>For
                            Public Relations&nbsp;</b><br />
                            Contact Amelia Noble<br />
                            E-Mail: <a href="mailto:amelia@techie-index.com">amelia@techie-index.com</a></p>
                    </td>
                </tr>
            </table>
        </div>
    )
}

export default Contact;