import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Styles.css';
import Cookies from 'js-cookie';
import Table from './Table';
import SubmissionResult from './SubmissionResult';

const Labform = ({ selectedTest, onSelectTest, testid }) => {
    const userid = Cookies.get('userid');
    const portalid = Cookies.get('portalid');
    const username = Cookies.get('name');
    const [parameters, setParameters] = useState([]);
    const [headerTitle, setHeaderTitle] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [resultHistory, setResultHistory] = useState([]);
    const [values, setValues] = useState({});
    const [submissionResult, setSubmissionResult] = useState('');
    const [procedureCategory, setProcedureCategory] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        axios.get(`/api/parameters/${selectedTest}/${testid}`)
            .then(response => {
                if (response.data && response.data.length > 0) {
                    setProcedureCategory(response.data[0].procedure_category);
                    setParameters(response.data);

                    const initialValues = response.data.reduce((acc, param) => {
                        acc[param.name] = '';
                        return acc;
                    }, {});
                    setValues(initialValues);
                }
            })
            .catch(error => {
                setErrorMessage('Error fetching parameters: ' + error.message);
            });

        axios.get(`/api/header/${selectedTest}`)
            .then(response => {
                if (response.data && response.data.length > 0) {
                    setHeaderTitle(response.data[0].NAME);
                } else {
                    setHeaderTitle('Header Not Found');
                }
            })
            .catch(error => {
                setErrorMessage('Error fetching header: ' + error.message);
            });

        axios.get(`/api/result-history/${userid}`)
            .then(response => {
                setResultHistory(response.data);
            })
            .catch(error => {
                console.error('Error fetching result history:', error);
            });
    }, [selectedTest, userid]);

    const handleInputChange = (e, paramName) => {
        const updatedValues = { ...values };

        if (paramName === 'gender') {
            // If gender changes to male, clear the pregnancies field
            if (e.target.value === 'male') {
                updatedValues['Pregnancies'] = '';
            }
        }

        updatedValues[paramName] = e.target.value;
        setValues(updatedValues);
    };

    const handleSubmit = () => {
        setIsLoading(true);
        setSubmissionResult('');
        const additionalFields = {
            age: values.age,
            gender: values.gender,
            height: values.height,
            weight: values.weight,
        };
        const allFormValues = { ...values, ...additionalFields };

        axios.post('/api/submit', { values: allFormValues, portalid: portalid, userid: userid, username: username, selectedTest })


            .then(response => {
                try {

                    console.log("response.data", response)
                    // const parsedData = JSON.parse(response.data.received_data_from_python.output);
                    if (response.status === 200) {
                        setSubmissionResult(response.data);
                    }

                } catch (error) {
                    // setSubmissionResult('Submission failed');
                    // setErrorMessage(response.data)
                    console.error('Error submitting values1: ', error.data);
                }
            })
            .catch(error => {
                console.error('Error submitting values2: ', error.response.data.error);
                // setSubmissionResult('Submission failed');
                setErrorMessage(error.response.data.error)
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div className="labform-and-history-container">
            <div className="labform-container">
                <Table parameters={parameters} values={values} handleInputChange={handleInputChange} procedureCategory={procedureCategory} />
                <button onClick={handleSubmit} disabled={isLoading}>
                    {isLoading ? 'Submitting...' : 'Submit'}
                </button>
                {isLoading && (
                    <div className="loader">
                        <img src="../images/website_images/Spinner-1s-200px.gif" alt="Loading..." style={{ width: '100px', height: '100px' }} />
                    </div>
                )}
                {/* {submissionResult && (
                    <div className="submission-result">
                        <SubmissionResult submissionResult={submissionResult} />
                    </div>
                )} */}

                {submissionResult !== undefined && submissionResult !== null && submissionResult !== '' ? (
                    <div className="submission-result">
                        {submissionResult === 0 ? (
                            <p>You may not have risk of diabetes in current case </p>
                        ) : (
                            <p>You may be at risk of having diabetes</p>
                        )}
                    </div>
                ) : (
                    <div className="submission-result">
                        <p>{errorMessage}</p>
                    </div>
                )}






            </div>
        </div>
    );
};

export default Labform;