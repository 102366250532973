import React, { useState } from 'react';
import axios from 'axios';
import './addsendingemails.css';
import Cookies from 'js-cookie';

function AddSendingEmails() {
  const [emailContent, setEmailContent] = useState('');
  const firmid = Cookies.get("firmid")
  const userid = Cookies.get("userid")

  const isValidEmail = (email) => {
    // Regular expression for basic email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };


  const handleSubmit = async() => {
    // Implement your submit logic here
    console.log('Email content submitted:', emailContent);

    const emails = emailContent.split(/[,\s;]+/);
    //const uniqueEmails = [...new Set(emails.filter(email => email !== ''))];
    const uniqueEmails = emails.filter(email => email !== '' && isValidEmail(email));

    console.log("validEmails", uniqueEmails);

    if (uniqueEmails.length === 0) {
      console.error('No valid emails to submit');
      alert("No valid emails to submit")
      return;
    }

    try {
      // Make a POST request to your backend API
      await axios.post('/load/emails', {
        uniqueEmails,
        userid,
        firmid,
      });
      console.log('Emails sent to the backend successfully');
      setEmailContent('');
    } catch (error) {
      console.error('Error sending emails to the backend:', error);
    }



  };

  return (
    <div className='sending-emails'>
      <h2>Add Sending Emails</h2>
      <textarea
        value={emailContent}
        onChange={(e) => setEmailContent(e.target.value)}
        placeholder="Enter email content here"
        rows="4"
        cols="50"
      />
      <br /><br />
      <button onClick={handleSubmit}>Submit</button>
    </div>
  );
}

export default AddSendingEmails;