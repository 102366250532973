import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import FacebookPostSchedule from './FacebookPostSchedule';
import './grouplistschedule.css';

// import './style.css';

function GroupListSchedule(props) {

    const { id } = props;
    const portalid = Cookies.get("portalid");
    const firmid = Cookies.get("firmid");
    const facebook_token = Cookies.get("facebook_token");
    const [grouplist, setGroupList] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedUrls, setSelectedUrls] = useState([]);
    const userid = Cookies.get("userid")
    const extractedIDs = [];
    const [textAreaValue, setTextAreaValue] = useState('');



    useEffect(() => {
        axios.post('/group/list', { "userid": userid, "firmid": firmid })
            .then(function (response) {
                //console.log("group list", response.data);


                for (const item of response.data) {
                    //debugger
                    const match = item.SOCIAL_URL.match(/\d+/);

                    // Check if a match was found

                    console.log("dbdate", item.TOKEN_DATE)

                    let dbdate = new Date(item.TOKEN_DATE);



                    console.log("dbdate", dbdate)



                    let currentdate1 = new Date();
                    const currentDate = currentdate1
                    // console.log("ISTDate",ISTDate)
                    currentDate.setHours(currentDate.getHours() + 5); // IST is UTC+5
                    currentDate.setMinutes(currentDate.getMinutes() + 30); // IST is UTC+5:30

                    // // Format the ISTDate to a string in 'yyyy-MM-dd HH:mm:ss' format
                    currentDate.toISOString().slice(0, 19).replace('T', ' ');


                    //const currentDate1 =currentDate.getTime();
                    console.log("currentDate", currentDate)




                    if (item.ACCOUNT_TYPE === 'Page') {
                        if (item.TOKEN_DATE) {
                            const diff = currentdate1 - dbdate
                            //console.log ("diff",diff)

                            const daysDifference = Math.floor((diff) / (1000 * 60 * 60 * 24)); // Calculate the difference in days

                            //console.log ("daysDifference",daysDifference)

                            if (daysDifference > 85) {

                                getpageaccesstoken(item.ID, match[0], currentDate)
                            }
                        }
                        else
                            getpageaccesstoken(item.ID, match[0], currentDate)

                    }

                }
                getlist();
            });



    }, []);


    const getlist = () => {

        axios.post('/group/list', { "userid": userid, "firmid": firmid })
            .then(function (response) {
                //console.log("group list", response.data);
                setGroupList(response.data)
            })
    }


    const getpageaccesstoken = async (id, match, currentDate) => {

        try {
            const response = await axios.get(`https://graph.facebook.com/${match}?fields=access_token&access_token=${facebook_token}`);

            const postHistoryResponse = await axios.post('/facebook/set/token', { "id": id, "PAGE_ACCESS_TOKEN": response.data.access_token, "TOKEN_DATE": currentDate });
            console.log("result", postHistoryResponse);

        }
        catch (error) {
            console.log(error);
        }

        getlist();
    }


    useEffect(() => {
        if (selectAll) {
            setSelectedRows(grouplist.map(item => item.ID));
        } else {
            setSelectedRows([]);
        }
    }, [selectAll, grouplist]);

    const toggleSelectAll = () => {
        setSelectAll(!selectAll);
    };


    // const toggleSelectRow = (rowId) => {
    //     if (selectedRows.includes(rowId)) {
    //         setSelectedRows(selectedRows.filter(id => id !== rowId));
    //     } else {
    //         setSelectedRows([...selectedRows, rowId]);
    //     }
    // };

    const toggleSelectRow = (rowId) => {
        // Only allow one checkbox to be checked at a time
        setSelectedRows([rowId]);
    };


    useEffect(() => {
        const selectedUrls = grouplist
            .filter(item => selectedRows.includes(item.ID))
            .map(item => ({
                SOCIAL_URL: item.SOCIAL_URL,
                PAGE_ACCESS_TOKEN: item.PAGE_ACCESS_TOKEN,
                TOKEN_DATE: item.TOKEN_DATE,
            }));
        setSelectedUrls(selectedUrls);
    }, [selectedRows, grouplist]);



    console.log("grouplist", grouplist)

    console.log("selectedUrls", selectedUrls)


    selectedUrls.forEach((selectedData) => {
        // Use a regular expression to match numbers in the URL
        const match = selectedData.SOCIAL_URL.match(/\d+/);

        // Check if a match was found
        if (match) {
            const type = selectedData.SOCIAL_URL.includes("group") ? "group" : "page";
            // Extracted number is in match[0]
            const extractedNumberObject = { group_page_id: match[0], type: type, PAGE_ACCESS_TOKEN: selectedData.PAGE_ACCESS_TOKEN, TOKEN_DATE: selectedData.TOKEN_DATE, };
            extractedIDs.push(extractedNumberObject);
        }
    });

    console.log("extractedIDs", extractedIDs);

    return (
        <div className='grouplistschedule-container'>
            <h3>Facebook Scheduling</h3>

            <textarea
            className='grouplistschedule-textarea'
                value={textAreaValue}
                onChange={(e) => setTextAreaValue(e.target.value)}
                placeholder="Enter your Facebook post caption here"
            />

            <h1>Select a Post/Page</h1>

            <table className="content-table">
                <thead>
                    <tr>
                        <th className="checkbox-header">

                            {/* <input
                            type="checkbox"
                            onChange={toggleSelectAll}
                            checked={selectAll}
                        /> */}

                        </th>
                        <th>ID</th>
                        <th>Group/Page Url</th>
                        <th>Group/Page Description</th>
                        <th>Group or Page </th>
                    </tr>
                </thead>
                <tbody>
                    {grouplist.map((item) => (
                        <tr key={item.ID}>
                            <td className="checkbox-column">
                                <input
                                    type="checkbox"
                                    onChange={() => toggleSelectRow(item.ID)}
                                    checked={selectedRows.includes(item.ID)}
                                />
                            </td>
                            <td>{item.ID}</td>
                            <td>{item.SOCIAL_URL}</td>
                            <td>{item.URL_NAME}</td>
                            <td>{item.ACCOUNT_TYPE}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <FacebookPostSchedule bardStory={textAreaValue} id={id} extractedIDs={extractedIDs} />
        </div>
    );
}

export default GroupListSchedule;