import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './viewmultipleimages.css'
import GroupListSchedule from './Facebook/GroupListSchedule';
import InstaGroupListShedule from './Instagram/InstaGroupListShedule';
import PageLog from '../../PageLog/PageLog';


function ViewMultipleImages() {

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const mid = query.get('mid');
  const [images, setImage] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const navigate = useNavigate()


  useEffect(() => {
    // axios.post('/multipleimages/get/image/link', { "mid": mid })
    //     .then(function (response) {
    //         //console.log("image link", response.data[0].image);
    //         setImage(response.data)

    //     });


    // axios.post('/multipleimages/get/image/link', { "mid": mid })
    // .then(function (response) {
    //   const cleanedImages = response.data.map(imageItem => {
    //     const rawImage = imageItem.image;
    //     let cleanedImage = rawImage.replace('..', ''); // Remove the leading two dots
    //     return window.location.origin + cleanedImage;
    //   });
    //   console.log("image links in insta", cleanedImages);
    //   setImage(cleanedImages);
    // });

    axios.post('/multipleimages/get/image/link', { "mid": mid })
      .then(function (response) {
        console.log("response.data", response.data)
        const cleanedImages = response.data.map(imageItem => {
          const rawImage = imageItem.image;
          let cleanedImage = rawImage.replace('..', ''); // Remove the leading two dots
          //const imageUrl = window.location.origin + cleanedImage;
          const imageUrl = "https://www.myblocks.in"+cleanedImage

          return { id: imageItem.id, image: imageUrl };
        });
        console.log("image links in insta", cleanedImages);
        setImage(cleanedImages);
      });
    //fetchhistory();



  }, []);

  console.log("image", images)
  console.log("selectedImages", selectedImages[0])




  // const handleImageClick = (id) => {
  //   // Toggle selection
  //   setSelectedImages((prevSelectedImages) => {
  //     if (prevSelectedImages.includes(id)) {
  //       return prevSelectedImages.filter((selectedId) => selectedId !== id);
  //     } else {
  //       return [...prevSelectedImages, id];
  //     }
  //   });
  // };

  const handleImageClick = (id) => {
    // Select only the clicked checkbox and deselect others
    setSelectedImages([id]);
  };

  const openschedule = () => {
    navigate(`/schedulemainpage?id=${selectedImages[0]}`);

  }

  return (

    //     <div className='viewmultipleimages-content-container'>
    //     <h1>ViewMultipleImages</h1>
    //     <div>
    //       {images && images.map((imageItem, index) => (

    //         <img key={index} src={imageItem.image} alt={`Image ${index + 1}`} style={{ width: '100px', height: '100px', margin: '5px' }} />
    //       ))}
    //     </div>
    //   </div>

    <div className='viewmultipleimages-content-container'>
      <PageLog/>

      <h1>Select an Image to schedule</h1>
      <div>
        {images && images.map((imageItem, index) => (
          <div key={index} style={{ position: 'relative', display: 'inline-block' }}>
            <input
              type="checkbox"
              checked={selectedImages.includes(imageItem.id)}
              onChange={() => handleImageClick(imageItem.id)}
              style={{ position: 'absolute', top: '5px', right: '5px' }}
            />
            <img
              src={imageItem.image}
              alt={`Image ${index + 1}`}
              style={{ width: '100px', height: '100px', margin: '5px' }}
            />
          </div>
        ))}
      </div>
      <br /><br />

      {/* <button>Post to Facebook/Instagram</button>
        <span style={{ margin: '0 50px' }}></span> */}

      <GroupListSchedule  id={selectedImages[0]} />

      <InstaGroupListShedule id={selectedImages[0]} />




      {/* <button onClick={openschedule}>Schedule Post</button> */}
    </div>

  )
}

export default ViewMultipleImages