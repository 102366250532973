import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import './bulkemailsending.css';

function BulkEmailSending({ selectedemails, subject, message }) {

    console.log("t", selectedemails, subject, message)

    const firmid = Cookies.get("firmid")
    const userid = Cookies.get("userid")
    const [myemail, setMyEmail] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [emailResponses, setEmailResponses] = useState([]);
    const [emaildone, setEmailDone] = useState('false');


    useEffect(() => {
        axios.get(`/get/myemailinfo?firmid=${firmid}&userid=${userid}`)
            .then((response) => {
                setMyEmail(response.data);
                console.log("myemail in response", response.data)
            })
            .catch((error) => {
                console.error('Failed to fetch projects:', error);
            });

    }, []);

    //console.log("myemail", myemail)

    useEffect(() => {

        if (myemail.length > 0) {
            // const finalpath = `nodemail`;
            //console.log("myemail inside useeffect", myemail)

            // const formData = new FormData();
           


            const sendNextEmail =  async() => {
                if (currentIndex < selectedemails.length) {
                    const emailObj = selectedemails[currentIndex];
                    const cleanedEmail = emailObj.EMAIL.trim();

                    // formData.append('to', cleanedEmail);
                    //debugger
                    // console.log("cleanedEmail",cleanedEmail)

                    // console.log("myemail[0].EMAIL",myemail)
                    //  console.log("myemail[0].PASSWORD",myemail[0].PASSWORD)

                    // const regex = /src="data:image\/([a-zA-Z]*);base64,([^"]*)"/g;
                    // const matches = message.match(regex);

                    // if (matches) {
                    //     //console.log('html :', html);
                        
                    //     matches.forEach((match, index) => {
                    //         processImage(match, index);
                    //     });
                    // }

                    // formData.append('subject', subject);
                    // formData.append('html', message);
                    // formData.append('email', myemail[0].EMAIL);
                    // formData.append('password', myemail[0].PASSWORD);
                   
                    //formData.append('finalpath', finalpath);

                    try {
                        const response = await axios.post('/bulk-send-email', {
                            to: cleanedEmail,
                            subject: subject,
                            html: message,
                            email: myemail[0].EMAIL,
                            password: myemail[0].PASSWORD,
                            host:myemail[0].HOST,
                            port:myemail[0].PORT,
                            secure:myemail[0].SECURE,
                            firmid:firmid,
                            userid:userid
                        });

                        const updatedResponses = [...emailResponses, response.data.message];
                        setEmailResponses(updatedResponses);

                        if (currentIndex === selectedemails.length - 1) {
                            // Set emaildone to true if this is the last email
                            setEmailDone(true);
                        }

                        setCurrentIndex(currentIndex + 1);
                    } catch (error) {
                        console.error('Failed to send email:', error);
                    }
                }
            };

            sendNextEmail();

        }
    }, [currentIndex, selectedemails, subject, message, myemail]);


    // const processImage = (match, index) => {

    //     debugger;

    //     const base64Data = match.split(',')[1];
    //     const innerMatches = base64Data.match(/^data:image\/([A-Za-z-+\/]+);base64,(.+)$/);
    //     console.log('itr :');
    //     alert("hi");
    
    //     if (!innerMatches || innerMatches.length !== 3) {
    //         console.error('Error processing image at index', index);
    //         return;
    //     }
    
    //     const imageType = innerMatches[1];
    //     const base64Data1 = innerMatches[2];
    //     console.log("base64Data1", base64Data1);
    
    //     const img = Buffer.from(base64Data1, 'base64');
    //     console.log("img", img);
    
    //    // formData.append('images', img);
    // };


    const cancelsending = () => {

        window.location.reload();
    }

    return (
        <div className="bulk-email-sending">
            <h3>Sending Emails...</h3>
            <br />

            <div className='bulk-sending-div' >
                {emailResponses.map((response, index) => (
                    <p key={index}>{response}</p>
                ))}
            </div>

            {emaildone === 'false' ?
                (<div className='bulk-sending-div'>
                    <button onClick={cancelsending}>Cancel</button>
                </div>) : (
                    <div className='bulk-sending-div'>
                        <button onClick={cancelsending}>Done</button>
                    </div>)
            }

        </div>
    )
}

export default BulkEmailSending