import React, { useState, useEffect } from 'react';
//import './familygrouphome.css';
import axios from 'axios';
import Cookies from 'js-cookie';

function FamilyGroupHome() {
    const [showPopup, setShowPopup] = useState(false);
    const [fdetails, setFAmilyDetails] = useState([]);


    const [formData, setFormData] = useState({
        familyName: '',
        familyPlace: '',
        familyDescription: '',
    });

    let userid = Cookies.get("userid");
    let username = Cookies.get("username")

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    const closePopup = () => {
        setShowPopup(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    useEffect(() => {

        fetchPosts();
    }, []);


    const insertgroup = async () => {

        try {
            const insertgroupresponse = await axios.post('/insert/into/mydocfamily', {
                ...formData,
                userid: userid,
                username:username
            });
            console.log("result", insertgroupresponse);
            closePopup();
            fetchPosts();
            setFormData({
                familyName: '',
                familyPlace: '',
                familyDescription: '',
            });
        } catch (error) {
            // Handle errors from the request or response
            console.error("Error in Axios post request:", error);
        }
    }



    const fetchPosts = async () => {
        try {
            const response = await axios.get(`/api/populate/familygrouplist?userid=${userid}`);

            setFAmilyDetails(response.data)

            console.log("response", response.data)
        } catch (error) {
            console.error('Error fetching posts:', error);
        }
    };



    return (
        <div className='content-container-familygp'>
            <div className="w3-content w3-margin-top" style={{ maxWidth: '1400px' }}>
                <div className="w3-row-padding">
                    <div className="w3-onethird">
                        <div className="w3-container w3-card w3-white w3-margin-bottom">
                            <h2 className="w3-text-grey w3-padding-16">
                                <img src="../images/AGIApp/group_family.png" alt="Simply Easy Learning" width="80" height="80" />
                                &emsp;Family/Group
                            </h2>
                            {/* <a style={{ textDecoration: 'none' }} href="notification.php" className="notification">
                  <span>Inbox</span>
                  <span className="badge"></span>
                </a> */}
                            <br />
                            <br />
                            <form className="navbar-form-familygp navbar-left" >
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search family"
                                        id="search"

                                    />
                                </div>
                                <button type="submit" className="btn btn-default">Submit</button>
                            </form>
                            {/* <br /> */}


                            {/* <div className="w3-container">
                                <h2>
                                    <span style={{ fontSize: '1rem', fontWeight: 'bold' }}>
                                        <a style={{ textDecoration: 'none' }} href="contact.php?id=47">
                                            <img src="../images/AGIApp/group.jpg" className="img-circle" alt="Simply Easy Learning" width="50" height="50" />
                                            &emsp;jack
                                        </a>
                                    </span>
                                </h2>
                            </div> */}
                            <hr className='custom-hr' />

                            {fdetails.map((family, index) => (
                                <div key={index} className="w3-container">
                                    <h2>
                                        <span style={{ fontSize: '1rem', fontWeight: 'bold' }}>
                                            <a style={{ textDecoration: 'none' }} href={`/familygroupdetails?id=${family.FAMILY_ID}`}>
                                                <img src="../images/AGIApp/group.jpg" className="img-circle" alt="Simply Easy Learning" width="50" height="50" />
                                                &emsp;{family.FAMILY_NAME}
                                            </a>
                                        </span>
                                    </h2>
                                    <hr className='custom-hr' />
                                </div>
                            ))}

                            <br></br>
                        </div>
                    </div>
                </div>
            </div>

            <button class="open-button" onClick={togglePopup} >Add</button>

            {showPopup && (
                <div className="popup-container">
                    <div className="popup">
                        <button className="close-button" onClick={closePopup}>X</button>
                        <div>
                            <div className="form-group">
                                <label htmlFor="family"><b>Family/Group Name</b></label>
                                <input type="text" className="form-control" id="family" name="familyName" placeholder="Family Name" value={formData.familyName} onChange={handleChange} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="place"><b>Family/Group Place</b></label>
                                <input type="text" className="form-control" id="place" name="familyPlace" placeholder="Place" value={formData.familyPlace} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="description"><b>Family/Group Description</b></label>
                                <input type="text" className="form-control" id="description" name="familyDescription" placeholder="Description of family" value={formData.familyDescription} onChange={handleChange} />
                            </div>

                            <div className='submit-button-div'>
                                <button type="button" className="btn btn-default" onClick={insertgroup}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default FamilyGroupHome