import React, { useState, useEffect } from 'react';
import './MyApp.css'; // Replace 'styles.css' with the correct path to your CSS file
import Cookies from 'js-cookie';
import axios from 'axios';
import PageLogLocation from '../PageLog/PageLogLocation';


const AppHome = () => {
  const [activeLink, setActiveLink] = useState('MyNews'); // Initial active link is 'MyNews'
  const [showMyNewsContent, setShowMyNewsContent] = useState(false); // State to control content display
  const [portalid, setPortalId] = useState(''); // State to store portalid
  const [showReport, setShowReport] = useState(false);
  const [healthLink, setHealthLink] = useState(''); // State to store the health link

  useEffect(() => {
    // Retrieve portalid and userId from cookies
    const retrievedPortalId = Cookies.get('portalid');
    const retrievedUserId = Cookies.get('userid');

    if (retrievedPortalId) {
      console.log('Retrieved portalid:', retrievedPortalId);
      setPortalId(retrievedPortalId);
    }

    if (retrievedUserId) {
      console.log('Retrieved userId:', retrievedUserId);
      // Do something with retrievedUserId if needed
    }
  }, []);

  useEffect(() => {
    // Construct the cityLink using portalid
    const cityLink = `http://61.2.142.91:7100/CityFromId/${portalid}`;

    // Check if the portalid cookie is set
    if (!portalid) {
      // If the portalid cookie is not set, then display an error message
      console.error('Please set the portalid cookie before using this app.');
    }

    // Check if the cityLink is a valid URL
    if (!cityLink.startsWith('http://') && !cityLink.startsWith('https://')) {
      // If the cityLink is not a valid URL, then display an error message
      console.error('The cityLink is not a valid URL.');
    }
  }, [portalid]);

  // Handle the MyNews click event
  const handleMyNewsClick = () => {
    setActiveLink('MyNews');
    setShowMyNewsContent(true);
    setShowReport(false); // Hide the Report component when MyNews is clicked
  };

  const handleMyHealthClick = () => {
    // Retrieve the user ID (userId) from the cookie
    const retrievedUserId = Cookies.get('userid');

    if (!retrievedUserId) {
      console.error('User ID not found in the cookie.'); // Log an error message if userId is not found
      return;
    }

    console.log('Retrieved User ID:', retrievedUserId); // Log the retrieved userId

    // Make an Axios GET request to fetch the link based on the user ID from the cookie
    axios
      .get(`http://localhost:8432/api/fetch-link?userId=${retrievedUserId}`)
      .then((response) => {
        const link = response.data.link;

        if (link) {
          // If a valid link is received, set it to healthLink
          console.log('Received value from the backend:', link);
          setHealthLink(link);
        } else {
          console.error('No valid link received from the backend.');
        }

        setShowReport(true); // Display the Report component
        setShowMyNewsContent(false); // Hide the MyNews content
      })
      .catch((error) => {
        console.error('Error fetching link:', error);
      });
  };

  return (
    <div className="myApp-home">
      <PageLogLocation />
      <header className="myApp-header">
        <div className="myApp-link-box">
          {/* MyNews link */}

          <a href="/pindex" className={`myApp-link  ${activeLink === 'Profile' ? 'active' : ''}`} onClick={() => setActiveLink('pindex')}>
            Personal Index
          </a>
          

          <br />
          <a href="/Addkfdocmnt" className={`myApp-link`} onClick={() => setActiveLink('Addkfdocmnt')}>
            Add news
          </a>
          <br />
          <a href="/Addvendor" className={`myApp-link`} onClick={() => setActiveLink('Addvendor')}>
          Add Institutions<br />
           (Business / Vendors)
          </a>
          <br />
          <a
            href={`/CityFromId/${portalid}`} // Use the constructed cityLink as the href
            className={`myApp-link  ${activeLink === 'MyNews' ? 'active' : ''}`}
            onClick={() => {
              handleMyNewsClick();
            }}
          >
            MyNews
          </a>
          <br />
          {/* MyHealth link */}
          {/* <a
            href={healthLink || '#'} // Use healthLink if available, otherwise use '#'
            className={`link ${activeLink === 'MyHealth' ? 'active' : ''}`}
            onClick={() => {
              handleMyHealthClick();
            }}
          >
            MyHealth
          </a> */}

          {/* Other links */}

          <a href="Myhealth" className={`myApp-link  ${activeLink === 'Profile' ? 'active' : ''}`} onClick={() => setActiveLink('My Health')}>
            MyHealth
          </a>
          <br />
          <a href="EditProfile" className={`myApp-link  ${activeLink === 'Profile' ? 'active' : ''}`} onClick={() => setActiveLink('Profile')}>
            MyDoc
          </a>
          <br />
          <a href="#" className={`myApp-link  ${activeLink === 'MyOtherApp' ? 'active' : ''}`} onClick={() => setActiveLink('MyOtherApp')}>
            Other App
          </a>
          <br />
          <a href="topvolunteers" className={`myApp-link  ${activeLink === 'topvolunteers' ? 'active' : ''}`} onClick={() => setActiveLink('topvolunteers')}>
            Top Volunteers' Points
          </a>
          <br />
          {/* 
          <a href="BusinessHome" className={`myApp-link  ${activeLink === 'business' ? 'active' : ''}`} onClick={() => setActiveLink('business')}>
            Business
          </a>
          <br /> */}
          <a href="healthsurvey" className={`myApp-link`} onClick={() => setActiveLink('healthsurvey')}>
            Health Survey
          </a>
          <br />
          <a href="EditAccountProfile" className={`myApp-link`} onClick={() => setActiveLink('EditAccountProfile')}>
            My Profile
          </a>
          <br />
          <a href="testqmmain" className={`myApp-link`} onClick={() => setActiveLink('testqmmain')}>
            My Tests
          </a>
          {/* <br />
          <a href="imageupload" className={`myApp-link`} onClick={() => setActiveLink('imageupload')}>
          Upload To Social Media
          </a> */}
          <br />
          <a href="Point" className={`myApp-link`} onClick={() => setActiveLink('Point')}>
            My Points
          </a>
          {/* <br />
          <a href="mobilenews" className={`myApp-link`} onClick={() => setActiveLink('mobilenews')}>
            Mobile News
          </a> */}

        </div>
        {/* Conditional rendering for content */}
        <div className="myApp-content-container">
          {showMyNewsContent && (
            <iframe
              src={`http://61.2.142.91:7100/CityFromId/${portalid}`}
              title="My News"
              width="80%"
              height="100%"
              frameBorder="0"
            ></iframe>
          )}
          {/* {showReport && (
            <iframe
              src={healthLink || ''} // Use healthLink if available, otherwise empty string
              title="My Health"
              width="80%"
              height="100%"
              frameBorder="0"
            ></iframe>
          )} */}
        </div>
      </header>
    </div>
  );
};

export default AppHome;
