import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import './ApproveStory.scss';
import { Link } from "react-router-dom";
import { Nav } from 'react-bootstrap';
import Cookies from 'js-cookie';
import GroupList from './GroupList';
import FacebookLogin from './FacebookLogin'
import InstaGroupList from './Instagram_Posting/InstaGroupList';
import PageLog from '../../PageLog/PageLog';

function ApproveStory() {

    const approvestoryid = Cookies.get("approvestoryid");
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    let id = query.get('id');
    // const approved = query.get('approved');
    id = id || approvestoryid;
    const facebook_status = Cookies.get("facebook_status")
    const facebook_token = Cookies.get("facebook_token")
    // const [facebookTokenAvailable, setFacebookTokenAvailable] = useState(false);

    


    console.log("facebook_status in approve", facebook_status)
    console.log("id in url section", id);
    // console.log("approved", approved);



    const [bardStory, setBardStory] = useState('');
    const [pagemenu, setMenu] = useState('');



    const role_id = Cookies.get("role_id");
    const userid = Cookies.get("userid");
    const getPassword = Cookies.get("passwordData");
    const portalid = Cookies.get("portalid");
    const firmid = Cookies.get("firmid");
    const [imageurl, setUrl] = useState('');





    console.log("firmid", firmid);
    console.log("portalid", portalid);
    console.log("approvestoryid", approvestoryid);


    const navigate = useNavigate()
    const cityLink = `http://61.2.142.91:8400/CityFromId/${portalid}`;






    sessionStorage.setItem('loading', true);



    useEffect(() => {
        if (!userid) {
          alert("Please login");
          navigate('/login');
        } 
      }, [userid, navigate]);


    useEffect(() => {

        // axios.post('/raingauge/menu', { "role_id": role_id })
        //     .then(function (response) {
        //         //console.log(response.data);
        //         setMenu(response.data)
        //     })

        axios.post('/approvestorydetails', { "id": id })
            .then(function (response) {
                console.log("bardstory in approvestory", response.data[0].BARD_STORY);
                console.log("bardstory in image", response.data[0].image);
                setBardStory(response.data[0].BARD_STORY)

                let baseURL = "https://myblocks.in";
                let modifiedPath = baseURL + response.data[0].image.slice(2);
                setUrl(modifiedPath)
                console.log(modifiedPath);


            })



    }, []);

    const handleChange = (event) => {
        setBardStory(event.target.value);
    };

    const handleclick = () => {
        sessionStorage.clear();
        window.location.reload();
    };

    const handleApprove = () => {
        // if (approved === 'Y') {
        //     alert("Already Approved")
        //     navigate('/imageupload')
        // }
        // else {

        axios.post('/approvestory', { "id": id, "bardStory": bardStory })
            .then(function (response) {
                console.log(response);
                alert("Modified")
                //navigate('/imageupload')
            })

        // }
    }


    const handlePost = () => {


        axios.post('/posttofacebook', { "id": id, "bardStory": bardStory, "portalid": portalid })
            .then(function (response) {
                console.log(response);
                // alert("posted")
                // navigate('/imageupload')
            })


    }

    // const [showTextArea, setShowTextArea] = useState(false);

    // // Function to toggle the visibility of the textarea
    // const toggleTextArea = () => {
    //     setShowTextArea(!showTextArea);
    // };

    // const reloadPageIfNeeded = () => {
    //     if (facebook_status === '2') {
    //         //debugger
    //         Cookies.set('facebook_status', 3);
    //         window.location.reload();

    //     }
    //     if (facebook_status === '3') {
    //         //debugger
    //         Cookies.set('facebook_status', 4);
    //         window.location.reload();

    //     }
    // };

    // // Call the reloadPageIfNeeded function when the component renders
    // useEffect(() => {
    //     console.log("facebook_status",facebook_status)
    //     reloadPageIfNeeded();
    // }, []);

    // useEffect(() => {
    //     if (facebook_token) {
    //         setFacebookTokenAvailable(true);
    //     }
    // }, [facebook_token]);


    return (

        <div className="content-container">

            {['0', '1'].includes(facebook_status) && <FacebookLogin />}

            <div className="preview-post">
                <div className='approve-header'>Preview Social Media Post</div>
                <div className='post-content'>
                    {imageurl && (
                        <div className="image-preview-container">
                            <img src={imageurl} alt="Story" className="story-image" />
                        </div>
                    )}
                    <div className="content-wrapper">
                        <textarea
                            className="content-textarea"
                            rows={10}
                            cols={80}
                            value={bardStory}
                            onChange={handleChange}
                        />
                        <div className='approve-button-container'></div>
                        <button className="button-blue" onClick={handleApprove} >Save Changes</button>
                    </div>
                </div>
            </div>
            <br />
            <div className='previous-post'>
                <GroupList bardStory={bardStory} id={id} />
                <InstaGroupList bardStory={bardStory} id={id} />

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Incase of any other permission issues try modifying permissions <a href='https://www.facebook.com/settings?tab=business_tools&ref=business_login'>here</a>
            </div>
        </div>
    );
};

export default ApproveStory